import React from 'react'

const ResizeIcon = props => (
  <svg width={86} height={67} {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#EB635C" fillRule="nonzero">
        <path d="M78.68 0H7.153C3.204.005.005 3.267 0 7.293v52.415C.005 63.733 3.204 66.995 7.153 67H78.68c3.948-.005 7.148-3.267 7.153-7.293V7.293C85.828 3.267 82.628.005 78.68 0zm-.012 64.026H7.142c-2.37 0-4.292-1.959-4.292-4.375V14.528h80.11v45.123c0 2.416-1.921 4.375-4.292 4.375zM2.861 11.668V7.293c0-2.417 1.922-4.376 4.292-4.376H78.68c2.37 0 4.291 1.96 4.291 4.376v4.375H2.861z" />
        <path d="M10.014 5.834h-1.43c-.79 0-1.431.653-1.431 1.458 0 .806.64 1.459 1.43 1.459h1.43c.79 0 1.431-.653 1.431-1.458 0-.806-.64-1.459-1.43-1.459zM16.28 5.95a1.288 1.288 0 0 0-1.088 0 1.649 1.649 0 0 0-.472.307 1.5 1.5 0 0 0 0 2.071c.14.129.299.232.472.306.347.156.741.156 1.087 0 .174-.074.333-.177.473-.306a1.5 1.5 0 0 0 0-2.071 1.649 1.649 0 0 0-.473-.306zM22.007 5.937a1.41 1.41 0 0 0-1.565.32 1.69 1.69 0 0 0-.3.481 1.398 1.398 0 0 0 0 1.109c.073.176.174.34.3.481a1.43 1.43 0 0 0 2.032 0 1.69 1.69 0 0 0 .3-.481c.08-.173.12-.363.115-.555a1.459 1.459 0 0 0-.882-1.355zM77.25 5.834H28.61c-.79 0-1.43.653-1.43 1.458 0 .806.64 1.459 1.43 1.459h48.64c.79 0 1.43-.653 1.43-1.459 0-.805-.64-1.458-1.43-1.458z" />
      </g>
      <g stroke="#EB635C" strokeLinecap="square" strokeWidth={2.869}>
        <path d="M48.653 23.696l9.975-.096M58.531 33.575l.097-9.975M27.301 45.048l-.096 9.975M37.18 54.926l-9.975.097M29.26 52.968l28.245-28.246" />
      </g>
    </g>
  </svg>
)

export default ResizeIcon
