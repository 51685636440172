import React from 'react'

const ModalX = props => (
  <svg width={14} height={15} {...props}>
    <g transform="translate(-1)" fill="#CBCDD2" fillRule="evenodd">
      <rect
        transform="rotate(45 8 7.5)"
        x={7}
        y={-2}
        width={2}
        height={19}
        rx={1}
      />
      <rect
        transform="rotate(-45 8 7.5)"
        x={7}
        y={-2}
        width={2}
        height={19}
        rx={1}
      />
    </g>
  </svg>
)

export default ModalX
