import React from 'react';
import './faq-card.css';

const FaqCard = (props) => {
  return (

    <div className='faq-card'>
      <div className='faq-card-inner-container'>
        <div className='faq-card-question-title'>
          {props.question}
        </div>
        <div className='faq-card-question-answer'>
          {props.answer}
        </div>
      </div>

    </div>
  )
}

export default FaqCard;
