import React from 'react'

const EditProfilePhotoCamera = props => (
  <svg width={25} height={20} {...props}>
    <path
      d="M12.5 7.5a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5zm10-3.75h-3c-.413 0-.857-.32-.987-.712L17.737.712C17.607.32 17.163 0 16.75 0h-8.5c-.412 0-.857.32-.987.712l-.776 2.327c-.13.391-.575.711-.987.711h-3A2.507 2.507 0 0 0 0 6.25V17.5C0 18.875 1.125 20 2.5 20h20c1.375 0 2.5-1.125 2.5-2.5V6.25c0-1.375-1.125-2.5-2.5-2.5zm-10 13.75a6.25 6.25 0 1 1 0-12.5 6.25 6.25 0 0 1 0 12.5zm9.125-9.502a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
)

export default EditProfilePhotoCamera
